import React from "react";
import amitSir from "../../src/assets/img/advisors/amitSir.png";
import mrPuneet from "../../src/assets/img/advisors/mrPuneet.png";
import ravindraSir from "../../src/assets/img/advisors/ravindraSir.png";

const Advisors = () => {
  return (
    <div>
      <div className="relative z-10 flex w-full justify-center bg-primary py-40 px-6 text-center before:absolute before:top-64 before:bottom-80 before:left-0 before:right-0 before:-z-[1] before:blur-[300px] min-[480px]:px-12 md:min-w-[calc(100vh_-_60px)]">
        <div className="flex max-h-max max-w-[1284px] flex-col gap-20 md:items-center md:justify-center">
          <div className="flex w-full flex-col gap-16 text-center">
            {/* <h3 className="text-gradient-primary relative z-10 mt-16 pl-4 md:pl-0 text-3xl font-bold leading-7 border-l-[4px] w-full xs:border-none xs:border-[color:var(--secondary-color)] md:text-6xl md:leading-[2] lg:leading-[1.25]"> */}
            <h3 className="relative z-10 text-4xl font-black leading-7 text-tertiary md:text-6xl md:leading-[2] lg:leading-[1.25]">
              Meet our advisors
            </h3>
          </div>
          <div className="flex w-full flex-col gap-10">
            <div className="flex flex-col gap-12 lg:flex-row">
              <div className="flex flex-1 flex-col items-center justify-start gap-10 rounded-xl bg-white p-10 shadow-xl ">
                <div className="flex w-full justify-center">
                  {/* <div className="flex h-52 w-52 rounded-full bg-purple-600 md:h-64 md:w-60"> */}
                  <img
                    src={ravindraSir}
                    className="h-48 w-48 md:h-52 md:w-52  xl:h-64 xl:w-64"
                    alt=""
                  />
                  {/* </div> */}
                </div>
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col gap-[-1rem]">
                    <h4 className="text-2xl font-bold ">Mr. Ravindra Pandey</h4>
                    <h4 className="text-xl font-semibold text-secondary">
                      Ex- CIO, Independent Director - State Bank Of India
                    </h4>
                  </div>
                  <p className="text-xl opacity-70">
                    Senior banker with 37 years at State Bank of India,
                    excelling globally. Proven track record in top leadership
                    roles in top domestic and international assignments
                  </p>
                </div>
              </div>

              <div className="flex flex-1 flex-col items-center justify-start gap-10 rounded-xl bg-white p-10 shadow-xl">
                <div className="flex w-full justify-center">
                  {/* <div className="flex h-64 w-60 rounded-full bg-purple-600"> */}
                  <img
                    src={mrPuneet}
                    className="h-48 w-48 md:h-52 md:w-52  xl:h-64 xl:w-64"
                    alt=""
                  />
                  {/* </div> */}
                </div>
                <div className="flex flex-col items-center gap-4 text-center">
                  <div className="flex flex-col gap-[-1rem]">
                    <h4 className="text-2xl font-bold">Mr. Puneet Gupta </h4>
                    <h4 className="text-xl font-semibold text-secondary">
                       Co-Founder - Neofy, Polynomial
                    </h4>
                  </div>
                  <p className="w-3/4 text-xl opacity-70">
                    Acclaimed CTO and deep-tech innovator with over 80 US
                    patents to his credit. Extensive experience across Silicon
                    Valley, Europe & Asia in building cutting edge digital
                    platforms.
                  </p>
                </div>
              </div>
              <div className="flex flex-1 flex-col items-center justify-start  gap-10 rounded-xl bg-white p-10 shadow-md ">
                <div className="flex w-full justify-center">
                  {/* <div className="flex h-52 w-52 rounded-full bg-purple-600 md:h-64 md:w-60"> */}
                  <img
                    src={amitSir}
                    className="h-48 w-48 md:h-52 md:w-52  xl:h-64 xl:w-64"
                    alt=""
                  />
                  {/* </div> */}
                </div>
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col gap-[-1rem]">
                    <h4 className="text-2xl font-bold">Mr. Amit Balooni</h4>
                    <h4 className="text-xl font-semibold text-secondary">
                       Strategic Consultant - World Bank, Asian Development Bank
                    </h4>
                  </div>
                  <p className="text-xl opacity-70">
                    A seasoned global expert with 22+ years of Banking and
                    Consulting experience with HDFC, ICICI, HSBC, IFC, ADB and
                    other Fintechs
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advisors;
