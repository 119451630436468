import { useRef } from "react";
import { FormContextProvider } from "../Context/FormContext.js";
import Footer from "../components/Footer.jsx";
import Form from "../components/Form/Form.jsx";
import Navbar from "../components/Navbar.jsx";

const Contact = () => {
  const form = useRef();

  return (
    <div className="min-h-screen  font-[Montserrat]">
      <div className="relative">
        <Navbar page="contact" />
        {/* <div className="flex justify-center max-[480px]:text-left"> */}
        <div className=" flex min-h-[calc(100vh)] w-full justify-center">
          {/* <div className="max-w-[1284px] w-full flex flex-col items-center justify-center max-[480px]:text-left mt-48"> */}
          <div className="flex min-h-full w-full flex-col items-center justify-center max-[480px]:text-left">
            <FormContextProvider>
              <Form page="contact" />
            </FormContextProvider>
            {/* <SimpleForm /> */}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Contact;
