import { useResizeObserver } from "@wojtekmaj/react-hooks";
import React, { useCallback, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import privacyPolicyPdf from "../assets/docs/privacy-policy.pdf";

import Footer from "../components/Footer.jsx";
import Navbar from "../components/Navbar.jsx";

const options = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: "/standard_fonts/",
};

const resizeObserverOptions = {};

const maxWidth = "1024";

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.js",
//   import.meta.url
// ).toString();
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PrivacyPolicy = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [containerRef, setContainerRef] = useState(null);
  const [containerWidth, setContainerWidth] = useState();

  const onResize = useCallback((entries) => {
    const [entry] = entries;

    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);

  useResizeObserver(containerRef, resizeObserverOptions, onResize);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  return (
    <div className=" bg-[color:var(--bg-primary)] font-[Montserrat] text-white">
      <div className="relative">
        <Navbar page="privacy-policy" />
        <div
          className="Example__container__document pt-10"
          ref={setContainerRef}
        >
          <Document
            file={privacyPolicyPdf}
            onLoadSuccess={onDocumentLoadSuccess}
            options={options}
            className="flex w-full flex-col justify-center"
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                className="flex w-full justify-center"
                width={
                  containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth
                }
              />
            ))}
          </Document>
        </div>
        <Footer page="privacy-policy" />
      </div>
    </div>
  );
};

export default PrivacyPolicy;
