import React, { useRef } from "react";
import { BsArrowDown } from "react-icons/bs";
import Navbar from "../components/Navbar.jsx";

import analysis from "../assets/img/illustrations/benefits/analysis.png";
import digitalTransformation from "../assets/img/illustrations/benefits/digital-transformation.png";
import engineering from "../assets/img/illustrations/benefits/engineering.png";
import equideiStack from "../assets/img/illustrations/equideiStack.png";
import Card from "../components/Card";
import Footer from "../components/Footer";
import "../components/VerticalTextSlider/style.scss";

const Msme = () => {
  const textRef = useRef(null);

  // useEffect(() => {
  //     const texts = textRef.current.children;
  //     const textsLength = texts.length;
  //     let index = 0;
  //     const textInTimer = 3000;
  //     const textOutTimer = 2600;

  //     function animateText() {
  //         for (let i = 0; i < textsLength; i++) {
  //             texts[i].classList.remove("text-in", "text-out");
  //         }
  //         texts[index].classList.add("text-in");

  //         setTimeout(function () {
  //             texts[index].classList.add("text-out");
  //         }, textOutTimer);

  //         setTimeout(function () {
  //             if (index === textsLength - 1) {
  //                 index = 0;
  //             } else {
  //                 index++;
  //             }
  //             animateText();
  //         }, textInTimer);
  //     }
  //     if (textRef.current) {
  //         animateText();
  //     }
  // }, []);
  return (
    // <div className="flex flex-col items-center min-h-screen text-white bg-[color:var(--bg-primary)] ">
    <div className="min-h-screen bg-[color:var(--bg-primary)] text-white ">
      <div className="relative">
        <Navbar page="solution" />
        <div className="flex justify-center">
          <div className="">
            {/* <div className="z-[1] mt-36  relative before:absolute max-[480px]:px-6 max-[480px]:text-left text-center before:top-52 before:bottom-72 before:left-0 before:right-0 before:blur-[250px] before:-z-[1] before:bg-[linear-gradient(90deg,rgba(61,93,254,0.8)_0%,rgba(61,93,254,0.8)_6.25%,rgba(62,93,254,0.8)_12.5%,rgba(66,93,254,0.8)_18.75%,rgba(73,94,254,0.8)_25%,rgba(84,95,254,0.8)_31.25%,rgba(100,97,254,0.8)_37.5%,rgba(123,99,254,0.8)_43.75%,rgba(154,_102,_255,_0.8)_50%,rgba(184,_104,_255,_0.8)_56.25%,rgba(207,106,255,0.8)_62.5%,rgba(223,108,255,0.8)_68.75%,_rgba(234,109,255,0.8)_75%,_rgba(241,110,255,0.8)_81.25%,rgba(245,110,255,0.8)_87.5%,_rgba(246,110,255,0.8)_93.75%,rgba(246,110,255,0.8)_100%)] "> */}
            <div className="gradient-hero-home relative mt-16 flex min-h-[calc(100svh_-_64px)] flex-col justify-center gap-28  overflow-hidden min-[480px]:px-12">
              <div className="h-full">
                <div className="home-text relative z-10 flex h-full justify-center">
                  <p
                    ref={textRef}
                    className="m-0 flex h-full py-3 px-8 text-4xl font-bold leading-10 xs:justify-center xs:text-center sm:text-4xl md:w-[90%] md:text-6xl md:leading-[78px]"
                  >
                    Maximise your ROI with EquiDEI
                  </p>
                </div>
              </div>
              <div className="flex justify-center">
                <button className="absolute left-4 right-0 bottom-0 flex w-full p-0 xs:justify-center xs:p-12">
                  <BsArrowDown className="animation-duration animate-bounce stroke-[0.55] text-6xl" />
                </button>
              </div>
            </div>
            {/* <div className="w-[1284px] max-w-[1284px]"> */}
            {/* <div className="flex flex-col my-20 justify-center items-start gap-24  md:gap-36 px-6 md:items-center min-[480px]:px-12 md:min-h-[calc(100vh_-_180px)]"> */}
            <div className="my-40 flex flex-col items-start justify-center gap-24  px-6 min-[480px]:px-12 md:items-center md:gap-36">
              {/* <div className="flex flex-col items-start gap-6 md:items-center">
                                <h2 className="font-bold text-gradient-primary text-3xl lg:text-7xl lg:leading-[1.25]">Diverse. Secure. Rewarding.</h2>
                                <p className="text-xl opacity-75 md:text-4xl lg:w-4/5">Join Equidei’s movement towards positive impact with our blockchain protocol for monetizing factories.</p>
                            </div> */}
              <div className="flex w-full flex-col gap-6 sm:text-center md:items-center">
                <h3 className="text-gradient-primary text-3xl font-semibold lg:text-5xl">
                  Equidei’s Technology
                </h3>
                <p className="text-xl md:text-4xl lg:w-4/5">
                  <span className="opacity-75">Our </span>
                  <span className="font-semibold opacity-100">
                    DLT-based solution{" "}
                  </span>
                  <span className="opacity-75">
                    gathers data from Enterprise, Taxation and Financial Systems
                    across supply chains to track the operations, performance,
                    and cashflow.
                  </span>
                </p>
              </div>
            </div>

            {/* Our Features */}
            {/* <div className="my-20 flex min-h-[calc(100vh_-_60px)] flex-col gap-12 max-[900px]:px-12 max-[480px]:gap-6 max-[480px]:px-6 max-[480px]:text-left">
              <h3 className="relative z-10 text-center text-[1.6rem] font-bold leading-8 max-[420px]:border-l-[4px] max-[420px]:border-[#9c32f9] max-[420px]:pl-4 max-[420px]:text-left lg:text-4xl">
                Our Features
              </h3>
              <TransparentCardSection data={servicesData} />
            </div> */}

            <div className="my-20 flex flex-col items-center px-6">
              <div className="flex max-w-[1284px] flex-col gap-32 md:items-center">
                <div className="flex flex-col gap-24">
                  {/* <p>MSMEs across globe have unmet credit demand of <span className="bg-[image:linear-gradient(90deg,#A841F9_84.72%,#9F55FA_85.59%,#8C80FB_87.74%,#7DA3FD_90.04%,#70BEFE_92.2%,#68D2FE_94.5%,#63DDFF_96.8%,#61E1FF_99.1%)]">$5 Trillion+</span></p> */}
                  <p className="text-3xl font-bold md:text-center md:text-4xl">
                    MSMEs across globe have unmet credit demand of{" "}
                    <span className="text-gradient-primary">$5 Trillion+</span>
                  </p>
                  <div className="flex flex-col justify-center gap-8 md:items-start md:gap-32 lg:flex-row">
                    <div className="flex flex-col items-center opacity-90">
                      <p className="text-gradient-secondary text-7xl font-semibold md:text-9xl">
                        $300
                      </p>
                      <p className="text-gradient-secondary text-6xl font-semibold md:text-8xl">
                        Billion
                      </p>
                      <p className="mt-2 w-1/2 text-center text-xl md:text-3xl">
                        from MSMEs in India
                      </p>
                    </div>
                    <div className="flex flex-col items-center opacity-90">
                      <p className="text-gradient-tertiary text-7xl font-semibold md:text-9xl">
                        $109
                      </p>
                      <p className="text-gradient-tertiary text-6xl font-semibold md:text-8xl">
                        Billion
                      </p>
                      <p className="mt-2 w-1/2 text-center text-xl md:text-3xl">
                        from GST registered MSMEs
                      </p>
                    </div>
                    <div className="flex flex-col items-center opacity-90">
                      <p className="text-gradient-secondary text-7xl font-semibold md:text-9xl">
                        $30
                      </p>
                      <p className="text-gradient-secondary text-6xl font-semibold md:text-8xl">
                        Billion
                      </p>
                      <p className="mt-2 w-1/2 text-center text-xl md:text-3xl">
                        from MSMEs worth 10 Crore+
                      </p>
                    </div>
                  </div>
                  <p className="text-2xl font-bold md:text-center md:text-4xl">
                    Manufacturing SMEs in India are expected to grow at a{" "}
                    <span className="text-gradient-primary">CAGR of 8.3% </span>{" "}
                    from 2023-2028
                  </p>
                </div>
                <div className="flex flex-col gap-16 md:items-center">
                  <p className="text-3xl md:text-center md:text-5xl">
                    <span>
                      EquiDEI provides real-time turnover estimates for MSMEs,
                      delivering alerts for
                    </span>{" "}
                    <br />
                    <span className="font-semibold">
                      {" "}
                      proactive delinquency and asset management.
                    </span>
                  </p>
                  <p className="text-gradient-primary text-3xl font-bold md:text-5xl">
                    Streamline process to Elevate your FI
                  </p>
                  <div className="my-8 ">
                    <img src={equideiStack} alt="" />
                  </div>
                  <p className="text-3xl font-bold md:text-center md:text-5xl">
                    Blockchain enabled Infrastructure to build <br />{" "}
                    <span className="text-gradient-primary">
                      Trust in Industrial Finance
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="relative z-10 mb-20 flex min-h-[calc(100vh_-_60px)] px-6 before:absolute before:top-64 before:bottom-80 before:left-0 before:right-0 before:-z-[1] before:blur-[300px] min-[480px]:px-12 md:justify-center md:text-center">
              <div className="flex flex-col gap-16 md:text-center md:text-inherit lg:w-[1084px] lg:max-w-[1084px]">
                <h3 className="relative z-10 mt-16 text-3xl font-bold leading-7 max-[420px]:border-l-[4px] max-[420px]:border-[color:var(--secondary-color)] max-[420px]:pl-4 max-[420px]:text-left lg:text-5xl">
                  Benefits
                </h3>
                <div className="flex flex-col gap-16 md:gap-28">
                  <div className="flex flex-col gap-16 md:flex-row md:gap-28">
                    <div className="flex flex-col gap-3 md:items-center md:gap-6">
                      <img src={analysis} className="w-24" alt="" />
                      <div className="flex flex-col gap-3">
                        <h3 className="text-gradient-primary text-2xl font-bold md:text-3xl">
                          Improve Cash Flow
                        </h3>
                        <p className="text-xl opacity-80 md:text-2xl">
                          Increased profitability due to lower provisioning
                          required; and Higher return on capital.
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col gap-3 md:items-center md:gap-6">
                      <img src={engineering} className="w-24" alt="" />
                      <div className="flex flex-col gap-3">
                        <h3 className="text-gradient-primary text-2xl font-bold md:text-3xl">
                          Increases Governance
                        </h3>
                        <p className="text-xl opacity-80 md:text-2xl">
                          Better governance by Financial Institutions due to the
                          increased visibility around borrower state of affairs.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-3 md:items-center md:gap-6">
                    <img src={digitalTransformation} className="w-24" alt="" />
                    <div className="flex flex-col gap-3">
                      <h3 className="text-gradient-primary text-2xl font-bold md:text-3xl">
                        Intelligent Risk Management
                      </h3>
                      <p className="text-xl opacity-80 md:text-2xl">
                        Minimized likelihood of loan defaults through taking
                        preventive measures and Minimized exposure at default.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative z-10 flex min-h-[calc(100vh_-_60px)] justify-center px-6 pb-20 text-center before:absolute before:top-64 before:bottom-80 before:left-0 before:right-0 before:-z-[1] before:blur-[300px]">
              <div className="flex flex-col gap-16 max-[480px]:gap-6 max-[480px]:text-left lg:w-[1084px] lg:max-w-[1084px]">
                <h3 className="text-3xl font-bold xl:text-4xl">
                  The
                  <span className="text-gradient-primary mx-2">deployment</span>
                  of our solution consists of the following phases:
                </h3>
                <div className="flex flex-col items-center">
                  {/* <div className="grid min-w-0 min-h-0 gap-16 md:grid-rows-2 md:w-full max-w-max"> */}
                  <div className="flex max-w-max flex-col gap-16 md:w-full md:gap-0">
                    <div className="max-h-fit md:flex">
                      <Card
                        className="flex-1 bg-black shadow-[-8px_-8px_16px_rgba(99,99,99,0.05),8px_8px_16px_rgba(0,0,0,0.15)]"
                        heading="Data Integration"
                        paragraph1="The first step in deploying the TES solution is integrating multiple data sources."
                        paragraph2={`Our data source integration works with three data source categories:
                                                        1) Internal data sources of the MSME,
                                                        2) External data sources pertaining to the borrowers and
                                                        3) Data obtained through market research
                                                        We ensure data authenticity, feeding validated info to TES Analytics Engine. Our approach excels in curated, secure derivation.`}
                        headingClasses={"text-3xl bg-purple-700"}
                        button="true"
                        card="integration"
                      />
                      <div className="relative hidden flex-1 rounded-tr-3xl md:block">
                        <div className="absolute top-1/2 h-full w-1/2 rounded-tr-3xl border-t-4 border-r-4 border-dashed border-[var(--primary-color)]"></div>
                      </div>
                    </div>
                    <div className="flex max-h-fit md:pt-24 ">
                      <div className="relative hidden flex-1 justify-end md:flex">
                        <div className="absolute top-1/2 h-full w-1/2 rounded-tl-3xl border-t-4 border-l-4 border-dashed border-[var(--primary-color)]"></div>
                      </div>
                      <Card
                        className="flex-1 bg-black shadow-[-8px_-8px_16px_rgba(99,99,99,0.05),8px_8px_16px_rgba(0,0,0,0.15)]"
                        heading="Real Time Turnover Estimation"
                        paragraph1="Step two is leveraging data and AI for MSME turnover estimation and risk assessment"
                        paragraph2="EquiDEI leverages 20+ data points, finance info, and market insights to forecast Turnover of MSMEs. TES uses AI triggers on local MSME data, DLT-driven ML analyses encrypted info for upsell and risk prediction, updating key KPIs to assess performance of MSMEs and forecast their cashflows."
                        headingClasses={"text-3xl bg-purple-700"}
                        button="true"
                      />
                    </div>
                    <div className="flex md:pt-24">
                      <Card
                        className="flex-1 bg-black shadow-[-8px_-8px_16px_rgba(99,99,99,0.05),8px_8px_16px_rgba(0,0,0,0.15)]"
                        heading="Lead Generation"
                        paragraph1="Step three is enhancing ROI through upsell alerts, credit tracking, and default mitigation strategies in TES."
                        paragraph2="TES boosts ROI via upsell alerts, utilizing live credit tracking to suggest higher borrowing limits and new loans, minimizing churn. The system also identify early threats in potential defaults to propose restructuring of assets to minimise EAD and improve IRR."
                        headingClasses={"text-3xl bg-purple-700"}
                        button="true"
                      />
                      <div className="relative hidden flex-1 md:flex">
                        <div className="top-1/2 h-full w-1/2 rounded-tr-3xl"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Footer */}
            <Footer page="solution" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Msme;
