import React from "react";
import raghav from "../assets/img/team/raghav.png";
import rishav from "../assets/img/team/rishav.png";

const Team = () => {
  return (
    // <div className="relative z-10 flex justify-center rounded-3xl py-40 px-6 text-center shadow-[8px_8px_16px_0px_rgba(0,0,0,0.15),-8px_-8px_16px_0px_rgba(99,99,99,0.05)] before:absolute before:top-64 before:bottom-80 before:left-0 before:right-0 before:-z-[1] before:blur-[300px] min-[480px]:px-12 md:min-w-[calc(100vh_-_60px)]">
    <div className="relative z-10 flex justify-center rounded-3xl bg-[#0000000A] py-40 px-6 text-center before:absolute before:top-64 before:bottom-80 before:left-0 before:right-0 before:-z-[1] before:blur-[300px] min-[480px]:px-12 md:min-w-[calc(100vh_-_60px)]">
      <div className="flex max-h-max max-w-[1284px] flex-col justify-center gap-20 md:items-center">
        <div className="flex w-full xl:w-[1084px] xl:max-w-[1084px]">
          {/* <h3 className="text-gradient-primary relative z-10 text-3xl font-bold leading-7 w-full border-l-[4px] border-[color:var(--secondary-color)] pl-4 xs:border-none xs:border-l-0 md:text-6xl md:leading-[2] lg:leading-[1.25]"> */}
          <h3 className="relative z-10 w-full text-4xl font-black leading-7 text-primary md:text-6xl md:leading-[2] lg:leading-[1.25]">
            Meet our team
          </h3>
        </div>
        {/* <div className="flex w-full flex-col gap-10"> */}
        <div className="flex w-full flex-col gap-12 lg:flex-row">
          {/* <div className="flex flex-col items-center justify-center gap-12 rounded-full lg:flex-row"> */}
          <div className="hidden flex-[1/2] flex-col items-center justify-start gap-10 rounded-xl bg-transparent py-10 px-[64px] md:flex ">
            <div className="flex w-full justify-center"></div>
            <div className="flex flex-col items-center gap-4 text-center">
              <div className="flex flex-col gap-[-1rem]">
                <h4 className="text-2xl font-bold text-primary"></h4>
                <h4 className="text-2xl font-semibold"></h4>
              </div>
              <p className="w-3/4 text-xl opacity-70"></p>
            </div>
          </div>
          <div className="flex flex-1 flex-col items-center justify-center gap-10 rounded-xl bg-white p-10 shadow-xl">
            {/* <div className="mx-[-11.5px] flex flex-1 items-center justify-center"> */}
            <img src={raghav} className="h-52 w-52 md:h-64 md:w-64" alt="" />

            <div className="tex-center flex flex-[2] flex-col gap-4">
              <div className="flex flex-col gap-[-1rem]">
                <h4 className="text-2xl font-bold">Raghav Gupta</h4>
                <h4 className="text-xl font-semibold text-secondary">
                  Founder
                </h4>
              </div>
              {/* <p className="text-xl opacity-70 lg:w-3/4"> */}
              <p className="text-xl opacity-70">
                Industrial engineer motivated to enable Industry 4.0 adoption
                with manufacturing SMEs. Demonstrated experience working with
                state of the art technologies blockchain, AI and IoT based
                solutions.
              </p>
            </div>
          </div>
          <div className="flex flex-1 flex-col items-center justify-start gap-10 rounded-xl bg-white p-10 shadow-xl ">
            <img src={rishav} className="h-52 w-52 md:h-64 md:w-64" alt="" />
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-[-1rem]">
                <h4 className="text-2xl font-bold">Rishav Sharma</h4>
                <h4 className="text-xl font-semibold text-secondary">
                  Full Stack Developer
                </h4>
              </div>
              <p className="text-xl opacity-70">
                Developer dedicated to creating engaging and user-friendly
                fullstack apps, with a strong commitment to living a climate
                positive lifestyle and using his tech skills to contribute to a
                happier and sustainable planet.
              </p>
            </div>
          </div>
          <div className="hidden flex-[1/2] flex-col items-center justify-start gap-10 rounded-xl bg-transparent py-10 px-[64px] md:flex ">
            <div className="flex w-full justify-center"></div>
            <div className="flex flex-col items-center gap-4 text-center">
              <div className="flex flex-col gap-[-1rem]">
                <h4 className="text-2xl font-bold text-primary"></h4>
                <h4 className="text-2xl font-semibold"></h4>
              </div>
              <p className="w-3/4 text-xl opacity-70"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
