import debounce from "lodash.debounce";
import React, { createContext, useEffect, useState } from "react";

export const FormContext = createContext();

const initialState = {
  name: "",
  company: "",
  email: "",
  number: "",
  entity: "",
  message: "",
};

const getFromLocalStorage = () => {
  if (typeof window != "undefined") {
    const value = localStorage.getItem("formData");
    return value ? JSON.parse(value) : initialState;
  }
  return initialState;
};

export const FormContextProvider = ({ children }) => {
  const [formData, setFormData] = useState(() => {
    return getFromLocalStorage();
  });

  const saveFormDataToLocalStorage = debounce((data) => {
    localStorage.setItem("formData", JSON.stringify(data));
  }, 50);

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === "formData") {
        setFormData(JSON.parse(e.newValue));
      }
    };
    saveFormDataToLocalStorage(formData);

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [formData]);

  return (
    <FormContext.Provider value={{ formData, setFormData }}>
      {children}
    </FormContext.Provider>
  );
};
