import emailjs from "@emailjs/browser";
import React, { useRef, useState } from "react";
import contactImg from "../../assets/img/illustrations/contact.png";
import cn from "../../utils";

const Form = ({}) => {
  const [page, setPage] = useState(0);
  const form = useRef();
  const [nameValid, setNameValid] = useState(true);
  const [companyValid, setCompanyValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  const [mobileValid, setMobileValid] = useState(true);
  const [entityValid, setEntityValid] = useState(true);
  const [messageValid, setMessageValid] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const [name, setName] = useState();

  const [entityArray, setEntityArray] = useState([
    {
      entity: "MSME",
      selected: false,
    },
    {
      entity: "Lending Institutions",
      selected: false,
    },
    {
      entity: "Other",
      selected: false,
    },
  ]);

  // const PageDisplay = () => {
  //   if (page === 0) {
  //     return <PersonalInfo formData={formData} setFormData={setFormData} />;
  //   } else if (page === 1) {
  //     return <EntityType formData={formData} setFormData={setFormData} />;
  //   } else {
  //     return <OtherInfo formData={formData} setFormData={setFormData} />;
  //   }
  // };

  // useEffect(() => {
  //   !nameValid ||
  //     !companyValid ||
  //     !emailValid ||
  //     !mobileValid ||
  //     (!entityValid && setSubmitDisabled(true));
  // }, [third]);

  const validateName = (name) => {
    const isValid = name?.length > 0;
    setNameValid(isValid);
    return isValid;
  };

  const validateCompany = (company) => {
    const isValid = company?.length > 2;
    setCompanyValid(isValid);
    return isValid;
  };

  const validateEmail = (email) => {
    const isValid = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]$/.test(
      email
    );
    setEmailValid(isValid);
    return isValid;
  };

  const validateMobile = (mobile) => {
    const isValid = mobile?.length > 0;
    setMobileValid(isValid);
    return isValid;
  };

  const validateEntity = (entity) => {
    // Implement your entity validation logic
    const isValid = entityArray.some((item) => item.selected);
    setEntityValid(isValid);
    return isValid;
  };

  const sendEmail = (e) => {
    e.preventDefault();

    // Validate inputs before sending email
    // const isNameValid = validateName(formData.name);
    // const isCompanyValid = validateCompany(formData.company);
    // const isEmailValid = validateEmail(formData.email);
    // const isMobileValid = validateMobile(formData.mobile);
    // const isEntityValid = validateEntity(formData.entity);

    // if (
    //   isNameValid &&
    //   isCompanyValid &&
    //   isEmailValid &&
    //   isMobileValid &&
    //   isEntityValid
    // ) {
    // Continue with sending email
    emailjs
      .sendForm(
        "service_doru4kr",
        "template_816u6l5",
        form.current,
        "0vDDxwpO4jJVKny9v"
      )
      .then(
        (result) => {
          if (result.text === "OK") {
            console.log("Email sent successfully!");
          }
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  // const handleEntityInput = (entity) => {
  //   for (let i = 0; i <= 2; i++) {
  //     entityArray[i] && (entityArray[i].selected = false);
  //   }
  //   entityArray[entity] && (entityArray[entity].selected = true);
  // };
  const handleEntityInput = (selectedEntity) => {
    setEntityArray((prevArray) =>
      prevArray.map((entity) => ({
        ...entity,
        selected: entity.entity === selectedEntity,
      }))
    );
    console.log("Entity Array", entityArray);
  };

  return (
    <div className="flex w-full flex-col items-center justify-center   md:my-0 ">
      {/* <div className="relative flex min-h-[480px] w-full rounded-b-3xl shadow-[8px_8px_16px_0px_rgba(0,0,0,0.15),-8px_-8px_16px_0px_rgba(99,99,99,0.05)] lg:w-[80%]"> */}
      <div className="relative flex w-full flex-col md:flex-row">
        <div className="hidden flex-1 items-center justify-center bg-[radial-gradient(487.93%_127.1%_at_32.59%_-16.31%,#BC49C4_0%,#692CCD_100%)]  py-12 xl:flex xl:justify-end">
          <div className="flex max-w-[642px] items-center justify-center xl:justify-end">
            <img className="max-w-3xl " src={contactImg} alt="" />
          </div>
        </div>
        <div className="flex flex-1 flex-col justify-center gap-10 bg-tertiary px-6 py-32 ">
          <div className="max-w-[642px]">
            <div className="flex flex-col gap-2">
              <h3 className="rounded-b-md rounded-t-3xl py-3 text-4xl font-black md:text-6xl md:leading-[1.5]">
                Ready to begin an exciting journey?
              </h3>
              <h5 className="mb-8 text-base md:text-xl">
                You can reach us anytime via
                <a
                  className="pl-[5px] text-quaternary "
                  href="mailto:axia@equidei.com"
                >
                  axia@equidei.com
                </a>
              </h5>
            </div>

            <form
              className="flex w-full flex-col justify-between gap-8 md:gap-10"
              ref={form}
            >
              <div className="flex w-full flex-col justify-between gap-8 sm:flex-row">
                <div className="relative flex w-full flex-1 flex-col gap-1">
                  <label className="font-medium" htmlFor="">
                    Full Name
                  </label>
                  <input
                    className={cn(
                      !nameValid && "border-[1px] border-red-500",
                      "h-12 w-full rounded-md border-[1.5px] px-4 duration-500"
                    )}
                    name="full_name"
                    type="text"
                    placeholder="John Doe"
                    pattern="^[a-zA-Z]{3,20}$"
                    onChange={(e) => validateName(e.target.value)}
                    // onBlur=""
                    // onFocus=""
                    required={true}
                  />
                  {!nameValid && (
                    <p className="absolute -bottom-6  text-red-600">
                      Invalid name
                    </p>
                  )}
                </div>
                <div className="relative flex flex-1 flex-col gap-1">
                  <label className="font-medium" htmlFor="">
                    Company
                  </label>
                  <input
                    className={cn(
                      !companyValid && "border-[1px] border-red-500",
                      "h-12 w-full rounded-md border-[1.5px] px-4 duration-500"
                    )}
                    name="company_name"
                    type="text"
                    onChange={(e) => setCompanyValid(e.target.value)}
                    placeholder="XYZ Pvt. Ltd."
                    pattern="^[a-zA-Z]{3,20}$"
                    required
                  />
                  {!companyValid && (
                    <p className="absolute -bottom-6  text-red-600">
                      Invalid company name
                    </p>
                  )}
                </div>
              </div>
              <div className="flex w-full flex-col justify-between gap-8 sm:flex-row">
                <div className="relative flex flex-1 flex-col gap-1">
                  <label className="font-medium" htmlFor="">
                    Email
                  </label>
                  <input
                    className={cn(
                      !emailValid && "border-[1px] border-red-500",
                      "h-12 w-full rounded-md border-[1.5px] px-4 duration-500"
                    )}
                    name="user_email"
                    type="email"
                    placeholder="johndoe@gmail.com"
                    onChange={(e) => setEmailValid(e.target.value)}
                    pattern="^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
                    required
                  />
                  {!emailValid && (
                    <p className="absolute -bottom-6  text-red-600">
                      Invalid email
                    </p>
                  )}
                </div>
                <div className="relative flex flex-1 flex-col gap-1">
                  <label className="font-medium" htmlFor="">
                    Mobile No.
                  </label>
                  <input
                    className={cn(
                      !mobileValid && "border-[1px] border-red-500",
                      "h-12 w-full rounded-md border-[1.5px] px-4 duration-500"
                    )}
                    name="mobile_number"
                    type="number"
                    onChange={(e) => setMobileValid(e.target.value)}
                    placeholder="+91 9876543210"
                    pattern="[0-9]{10}"
                    required
                  />
                  {!mobileValid && (
                    <p className="absolute -bottom-6  text-red-600">
                      Invalid mobile number
                    </p>
                  )}
                </div>
              </div>
              <div>
                <h3 className={cn("mb-3 font-medium ")}>Select entity type</h3>
                <div className="flex flex-col flex-wrap gap-4">
                  <div className="s flex  flex-col gap-10 whitespace-nowrap md:flex-row">
                    {entityArray.map((entity, i) => {
                      return (
                        <div className="flex  items-center gap-6" key={i}>
                          <input
                            className={cn(
                              !entityValid && "border-[1px] border-red-500",
                              "h-12 w-6 px-4"
                            )}
                            name={`entity_type_${entity.entity}`}
                            type="radio"
                            onChange={() => handleEntityInput(entity.entity)}
                            checked={entity.selected}
                          />
                          <label className="text-lg" htmlFor="">
                            {entity.entity}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {!entityValid && (
                  <p className="text-red-500">Please select an entity type.</p>
                )}
              </div>
              <div className="flex w-full flex-col flex-wrap justify-between gap-8 sm:flex-row ">
                <div className="relative flex w-full flex-col gap-1 whitespace-nowrap">
                  <label className="font-medium" htmlFor="">
                    Message
                  </label>
                  <textarea
                    className={cn(
                      !messageValid && " border-red-500",
                      "w-full rounded-md border-[1.5px] p-4 duration-500"
                    )}
                    name="message"
                    rows="5"
                    type="text"
                    onChange={(e) => setMessageValid(e.target.value)}
                    placeholder="Tell us what you want to say..."
                    required
                  />
                  {!messageValid && (
                    <p className="absolute -bottom-6  text-red-600">
                      Invalid message
                    </p>
                  )}
                </div>
              </div>
              <button
                disabled={
                  !nameValid ||
                  !companyValid ||
                  !emailValid ||
                  !mobileValid ||
                  !entityValid
                }
                onClick={sendEmail}
                className={cn(
                  submitDisabled && "bg-gray-400 bg-[image:none]",
                  "rounded-md bg-[radial-gradient(487.93%_127.1%_at_32.59%_-16.31%,#BC49C4_0%,#692CCD_100%)] px-6 py-3 font-semibold text-white sm:text-lg"
                )}
              >
                Submit
              </button>
            </form>
            {/* {PageDisplay()} */}
            {/* <div className="left-0  flex w-full flex-col justify-between gap-8 p-10 xs:-bottom-2 sm:absolute sm:bottom-0 sm:flex-row">
            <div className="">
              <button
                onClick={() => {
                  setPage((currPage) => currPage - 1);
                }}
                className={`${
                  page === 0 && "hidden"
                } max-w-max rounded-xl px-6 py-3 font-semibold shadow-[8px_8px_16px_0px_rgba(0,0,0,0.15),-8px_-8px_16px_0px_rgba(99,99,99,0.05)] sm:mt-8  sm:px-8 sm:py-2 sm:text-lg`}
              >
                Previous
              </button>
            </div>
            <div
              onClick={() => {
                setPage((currPage) => currPage + 1);
              }}
              className=""
            >
              {page < 2 ? (
                <button className="max-w-max rounded-xl bg-[image:var(--bg-gradient-primary)] px-6 py-2 font-semibold sm:mt-8">
                  Next
                </button>
              ) : (
                <button
                  onClick={sendEmail}
                  className="max-w-max rounded-xl bg-[image:var(--bg-gradient-primary)] px-6 py-2 font-semibold sm:mt-8 sm:text-lg"
                >
                  Submit
                </button>
              )}
            </div>
          </div> */}
          </div>
        </div>
      </div>

      {/* Hidden form - just created to send data using Emailjs */}
      {/* <form
        ref={form}
        className="z-20 mt-32 hidden flex-col gap-8 rounded-md bg-black p-6 py-16 shadow-[rgba(0,0,0,0.16)_0px_10px_36px_0px,rgba(1,1,1,0.06)_0px_0px_0px_1px] md:-ml-8 md:mt-16 md:flex-[1.2]"
      >
        <div className="flex flex-col gap-6">
          <input type="text" name="first_name" defaultValue={formData.name} />
          <input type="text" name="company" defaultValue={formData.company} />
          <input type="email" name="user_email" defaultValue={formData.email} />
          <input type="tel" name="user_number" defaultValue={formData.number} />
          <input type="text" name="entity" defaultValue={formData.entity} />
          <textarea name="message" defaultValue={formData.message} />
        </div>
      </form> */}
    </div>
  );
};

export default Form;
